<template>
<div>
    <button @click="modalOpen = true" class="settingButton"><img src="/img/setting.svg"></button>
    <teleport to="body">
        <div v-if="modalOpen" class="modal">
            <div>
                <reverse-when-focus @click="toZettaSite" themaColor="#00AFEC" class="modalButton">ゼッタリンクス</reverse-when-focus>
                <reverse-when-focus @click="logout" themaColor="#00AFEC" class="modalButton">ログアウト</reverse-when-focus>
                <reverse-when-focus themaColor="#FD5C5C" class="closeButton" @click="modalOpen = false">
                    閉じる
                </reverse-when-focus>
                <span class='infomations'>
                    <span class="libInfos" >
                        <img class="libInfo" src="/img/logo_built_with.png"/>
                        <img class="libInfo" src="/img/ZettaOnlyLogo.png"/>
                    </span>
                    <span class="verInfo">version {{version}}</span>
                </span>
            </div>
        </div>
    </teleport>
</div>
</template>
<script>
import ReverseWhenFocus from './parts/ReverseWhenFocusButton.vue';
import store from '../store';
import firebase from "firebase/app";
import "firebase/auth";
import router from '../router';
import info from '../Infomation'
    export default {
        name: "ModalSetting",
        components: {
            ReverseWhenFocus,
        },
        data() {
            return {
                modalOpen: false,
                version:info.version,
            }
        },
        methods:{
            logout(){
                firebase.auth().signOut().then(() => {
                    console.log("Signout");
                    store.commit('setUserInfo', {});
                    router.push('/SignIn');
                }).catch((error) => {
                    console.log(`ログアウト時にエラーが発生しました (${error})`);
                });
            },
            toZettaSite(){
                window.open("https://sota.zettalinx.co.jp/");
            }
        }
    }
</script>
<style scoped>
    .modal {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background-color: rgba(0,0,0,.5);
        display: flex;
        z-index: 100;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .modal div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:flex-end;
        background-color: white;
        width: 130px;
        height: 150px;
        padding: 30px;
    }
    .modalButton{
        margin-bottom: 30px;
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .closeButton{
        width: 60%;
        align-items: center;
        justify-content: center;
    }
    .settingButton{
        border: none;
        background: transparent;
    }
    .infomations{
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .libInfo{
        height: 30px;
        margin-bottom: 3px;
        display: block;
    }
    .libInfos{
        display: inline-block;
    }
    .verInfo{
        font-size:0.5rem;
        color: #DDD;
        writing-mode: vertical-rl;/* 楽しそうだから縦書きにしてみただけ */
    }
</style>