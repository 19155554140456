<template>
    <button :style="style">
        <div ref="svg"/>
        <slot/>
    </button>
</template>
<script>
/*
hover時に文字色と背景色が反転するボタン
*/
export default ({
    name:'ReverseWhenFocus',
    data(){
        return{
            pushDirectionX:'0px',
            pushDirectionY:'2px',
        }
    },
    props:{
        //文字色とボーダー色
        themaColor:{
            type: String,
            default:"#666",
            required:false
        },
        //svgのurl。svgのタグ内にfill/stroke属性無かったら操作できないので注意。
        svgimage:{
            type: String,
            default:'',
            required:false
        },
        //押したときの方向'under''top''right''left''none' デフォルトが'under'
        pushDirection:{
            type:String,
            default:'under',
            required:false
        },
        //押したときの深さ、単位px。デフォルト2px
        pushDepth:{
            type:String,
            default:"2",
            required:false
        }
    },
    created(){
        if(this.pushDirection=="top"){
            this.pushDirectionX = '0px';
            this.pushDirectionY = -1*Number(this.pushDepth)+'px';
        }else if(this.pushDirection=="right"){
            this.pushDirectionX = Number(this.pushDepth)+'px';
            this.pushDirectionY = '0px';
        }else if(this.pushDirection=="left"){
            this.pushDirectionX = -1*Number(this.pushDepth)+'px';
            this.pushDirectionY = '0px';
        }else if(this.pushDirection=="none"){
            this.pushDirectionX = '0px';
            this.pushDirectionY = '0px';
        }else{
            this.pushDirectionX = '0px';
            this.pushDirectionY = Number(this.pushDepth)+'px';
        }
    },
    async mounted(){
        if(this.svgimage){//img srcで取り込むとfill/strokeの操作ができなくなるので
            await fetch(this.svgimage)
            .then(responce=>{
                responce.text().then((text)=>{
                    const divSvg = this.$refs.svg;
                    divSvg.innerHTML = text;
                    const svg = divSvg.getElementsByTagName('svg');
                    //マウスオーバー時svgのfill/strokeを白くするイベント追加
                    this.$el.addEventListener("mouseover", ()=> {
                        svg[0].style.fill="#FFF";
                        svg[0].style.stroke="#FFF";
                    })
                    this.$el.addEventListener("mouseout", ()=> {
                        svg[0].style.fill = this.themaColor;
                        svg[0].style.stroke=this.themaColor;
                    })
                })
            })
        }
    },
    computed:{
        style(){
            return{
                '--thema-color':this.themaColor,
                '--push-direction-x':this.pushDirectionX,
                '--push-direction-y':this.pushDirectionY
            }
        }
    }
})
</script>

<style scoped>
    button{
        display: flex;
        align-items: center;
        border: 1px solid var(--thema-color);
        border-radius: 10px;
        background: #FFF;
        color: var(--thema-color);
        font-family:Meiryo ;
        font-size: 1rem;
        padding: 1%;
        transition: 0.5s;
    }
    button:hover{
        background: var(--thema-color);
        color: #FFF;
        transition: 0.5s;
    }
    button:active{
        transform: translate(var(--push-direction-x),var(--push-direction-y));
        transition: 0.1s;
    }
</style>>
