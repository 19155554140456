<template>
    <button class="svgButton" ref="svgButton">
        <img class="svg-img" ref="svg">
        <slot/>
    </button>
</template>

<script>
export default ({
    name:"SvgButton",
    data(){
        return{}
    },
    props:{
        svgImg:{
            default:'',
            type:String,
            required:false,
        },
    },
    mounted(){
        if(this.svgImg){
            this.$refs.svg.src = this.svgImg;
        }
    }
})
</script>
<style scoped>
img{
    vertical-align: middle;
}
.svgButton{
    background-color: transparent;
    margin: 1%;
    display: flex;
    border: 1px solid transparent;
    border-radius: 5%;
    height: 80%;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    color: #666;
    transition: 0.5s;
}
.svgButton:hover{
    background-color:#DDD;
    border:solid 1px #DDD;
    transition:  0.5s;
}
.svgButton:active{
    transform: translate(0,2px);
    transition: 0.1s;
}
.svg-img{
    height: 80%;
    width: auto;
    min-height: 20px;
}
</style>