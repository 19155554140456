<template>
    <div class="signIn">
        <div class="signInDoor">
            <img class="logo" src="/img/SotaRoom_Logo.png"/>
            <div class="handle">
                    <div class="handle-shadow"></div>
                    <div class="BtnAndMsg">
                        <reverse-when-focus pushDirection="right" pushDepth="15" class="signInBtn" themaColor="#00AFEC"  @click="googleAuth">Googleでログインする</reverse-when-focus>
                        <div v-if="err" class="errMsg">そのアカウントではログインできません</div>
                    </div>
            </div>
        </div>
        <img class="titleIMG" src="/img/SotaRoom_Top_img.jpg"/>
    </div>
</template>
<script>
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import store from '../store';
import router from '../router';
import ReverseWhenFocus from '../components/parts/ReverseWhenFocusButton.vue';

export default({
    name:"SignIn",
    components:{
        ReverseWhenFocus,
    },
    data(){
        return {
            err:false
        };
    },
    methods:{
        async googleAuth(){
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth()
            .signInWithPopup(provider)
            .then((result)=>
            {
                this.checkLisence(result)
            }).catch((error) => {
                console.log('error:' + error.message);
            })
        },
        async checkLisence(result){
            const email = result.user.email;
            const domain = email.split('@')[1];
            const db = firebase.firestore();
            await db.collection("SotaSimulationLisence").where("googleId", "==", '@'+ domain)
            .get()
            .then(async (querySnapShot)=>{
                console.log("domain chk")
                const items =[];
                querySnapShot.forEach((doc) => {
                    items.push({
                        id: doc.id,
                    });
                });
                if(items.length==0){
                    console.log("email chk")
                    await db.collection("SotaSimulationLisence").where("email", "==", email)
                    .get()
                    .then((querySnapShot)=>{
                        const items =[];
                        querySnapShot.forEach((doc) => {
                            items.push({
                                id: doc.id,
                            });
                        });
                        if(items.length==0){
                            console.log('disable account')
                            this.err = true;
                            return true;
                        }
                        else{
                            store.commit('setUserInfo', {
                                'photoURL':result.user.photoURL,
                                'displayName':result.user.displayName,
                                'email':result.user.email
                            });
                            router.push('/');
                            return true;
                        }
                    }).catch(()=>{
                        return false;
                    })
                }
                else{
                    store.commit('setUserInfo', {
                        'photoURL':result.user.photoURL,
                        'displayName':result.user.displayName,
                        'email':result.user.email
                    });
                    router.push('/');
                    return true;
                }
            }).catch(()=>{
                return false;
            })
        }
    }
})
</script>
<style scoped>
.signIn{
    height: 100vh;
    width: 100vw;
    min-height: 576px;
    min-width: 1024px;
    background: #F2F2F2;
    display: flex;
    justify-content: flex-end;
}
.signInDoor{
    width: 25%;
    height: 100%;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.BtnAndMsg{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    text-align: center;
}
.signInBtn{
    width: 60%;
    height: 40%;
    min-width: 200px;
    margin: 0 auto;
    margin-top: 1%;
    display:flex;
    white-space: pre;
    align-items: center;
    font-size: 1rem;
    justify-content: center;
}
.signInBtn:active{
    box-shadow: -15px 0px 0px #E6E6E6;
}
/*なんかうごかない
.signInBtn:active+.handle-shadow{
    transition: 0.1s;
    transform: translate(15px,0px);
}
*/
.logo{
    width: 90%;
    height: auto;
    margin-bottom: 10% ;
}
.handle{
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.handle-shadow{
    background: #E6E6E6;
    height: 45%;
    width: 20px;
    margin-left: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titleIMG{
    height: 100%;
    width: auto;
    max-width: 75%;
    right: 0;
    bottom: 0;
}
.errMsg{
    font-size: 12px;
    color: tomato;
}
</style>