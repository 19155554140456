import * as THREE from "three";
import Setting from '../Setting';

export { CreateTexture, ResizeRendererToDisplaySize, createSprite }

//吹き出し作る（テクスチャ貼るのは別）
function createSprite(texture: THREE.CanvasTexture, name: string, x: number, y: number, z: number): THREE.Sprite{
        const scaleMaster =200;//5
        const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(scaleMaster,scaleMaster/2,scaleMaster);
        sprite.position.set(x,y,z);
        sprite.name = name;
        return sprite;
}

//吹き出しのテクスチャを作る
function CreateTexture(text: string, isSotatalk: boolean): THREE.CanvasTexture{
    const canvas = document.createElement('canvas');

    canvas.width = Setting.ModelCanvas.sotaTalk.background.canvasWidth; // 小さいと文字がぼやける
    canvas.height = Setting.ModelCanvas.sotaTalk.background.canvasHeight; // 小さいと文字がぼやける 

    const ctx = canvas.getContext('2d');

    if(ctx == null)return new THREE.CanvasTexture(document.createElement('canvas'));
    if(isSotatalk){
        ctx.fillStyle = Setting.ModelCanvas.sotaTalk.background.colorSota;
        ctx.beginPath();
        ctx.moveTo(canvas.width*0.75, canvas.height*0.9);
        ctx.lineTo(canvas.width *0.95, canvas.height*0.9);
        ctx.lineTo(canvas.width, canvas.height);
        ctx.fill();
    }else{
        ctx.fillStyle = Setting.ModelCanvas.sotaTalk.background.colorMe;
        ctx.beginPath();
        ctx.moveTo(canvas.width*0.75, canvas.height*0.9);
        ctx.lineTo(canvas.width*0.95, canvas.height*0.9);
        ctx.lineTo(canvas.width*0.7, canvas.height);
        ctx.fill();
    }
    //角丸の四角を描いてる
    ctx.moveTo(canvas.width*0.05,0);
    ctx.lineTo(canvas.width*0.95,0);
    ctx.quadraticCurveTo(canvas.width,0,canvas.width,canvas.height*0.1);
    ctx.lineTo(canvas.width,canvas.height*0.8);
    ctx.quadraticCurveTo(canvas.width,canvas.height*0.9,canvas.width*0.95,canvas.height*0.9);
    ctx.lineTo(canvas.width*0.05,canvas.height*0.9);
    ctx.quadraticCurveTo(0,canvas.height*0.9,0,canvas.height*0.8);
    ctx.lineTo(0,canvas.height*0.1);
    ctx.quadraticCurveTo(0,0,canvas.width*0.05,0);
    ctx.fill();

    //ctx.fillRect(0, 0, canvas.width, canvas.height*0.9);
    if(isSotatalk){
        ctx.fillStyle = Setting.ModelCanvas.sotaTalk.font.colorSota;
    }else{
        ctx.fillStyle = Setting.ModelCanvas.sotaTalk.font.colorMe;
    }
    ctx.font = Setting.ModelCanvas.sotaTalk.font.style;

    //折り返し処理
    const oneLineStr = [];
    const chars = text.split('');
    if(50<chars.length){//長かったら...に置き換え
        chars.splice(48,(chars.length-48));
        chars.push("...")
    }
    let tmp = '';
    chars.forEach((char) =>{
        if(canvas.width-10 < ctx.measureText(tmp + char).width){
            oneLineStr.push(tmp);
            tmp ='';
            tmp +=char;
        }else{
            tmp += char;
        }
    })
    oneLineStr.push(tmp);

    for(let i = 0; i< oneLineStr.length; i++){
        ctx.fillText(
            oneLineStr[i],
            (canvas.width - ctx.measureText(oneLineStr[i]).width) / 2,//中央ぞろえ
            (canvas.height*0.9 / 2) - ((ctx.measureText(oneLineStr[i]).actualBoundingBoxAscent*oneLineStr.length) / 2 )+(ctx.measureText(oneLineStr[i]).actualBoundingBoxAscent*(i+1))//全行の長さで中央ぞろえ。かつ一行ずつ、ずらして配置していく
        );
    }
    return new THREE.CanvasTexture(canvas);
}
class ResizeRender{
    public needResize: boolean;
    public width: number;
    public height: number;
    constructor(needResize: boolean, width: number, height: number){
        this.needResize = needResize;
        this.width = width;
        this.height = height;
    }
}

//レンダーリサイズ制御
function ResizeRendererToDisplaySize(renderer: THREE.WebGLRenderer): ResizeRender{ //boolean{
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    //if (needResize) {
        const resizeRender = new ResizeRender(needResize, width, height);
        //this.render.setSize(width, height, false);
    //}
    return resizeRender//needResize;
}