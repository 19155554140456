import * as THREE from "three";
import { LoadingManager } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import Setting from '../Setting'

////初期設定

//シーン
//const scene = new THREE.Scene();

//レンダー
const render = null;

//カメラOrthographic
const camera = new THREE.PerspectiveCamera(
    Setting.ModelCanvas.camera.PerspectiveCamera.fov,
    Setting.ModelCanvas.camera.PerspectiveCamera.aspectX / Setting.ModelCanvas.camera.PerspectiveCamera.aspectY,
    Setting.ModelCanvas.camera.PerspectiveCamera.near, 
    Setting.ModelCanvas.camera.PerspectiveCamera.far
);
camera.position.set(
    Setting.ModelCanvas.camera.position.x,
    Setting.ModelCanvas.camera.position.y,
    Setting.ModelCanvas.camera.position.z
);
camera.lookAt(Setting.ModelCanvas.camera.lookAt);

//ライト
const light = new THREE.HemisphereLight(
    Setting.ModelCanvas.light.HemisphereLight.skyColor,
    Setting.ModelCanvas.light.HemisphereLight.groundColor,
    Setting.ModelCanvas.light.HemisphereLight.intensity
)
light.position.set(
    Setting.ModelCanvas.light.position.x,
    Setting.ModelCanvas.light.position.y,
    Setting.ModelCanvas.light.position.z
);

//ローダー
const manager = new LoadingManager();
const loader = new FBXLoader(manager);

export {
    //scene,
    render,
    camera,
    light,
    loader
}