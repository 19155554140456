import { Vue } from "vue-class-component";

//arg:selfはApp.vueのこと
// eslint-disable-next-line
export function SotaFunction(arg: string,self: Vue){
    return new Promise((resolve,reject)=>{
        if(typeof arg === 'string'){
            eval(arg);
            resolve(true);
        }else{
            reject(false);
        }
    });
}
export function WaitForSec (sec: number){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, sec);
    });
}