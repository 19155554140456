<template>
  <canvas id="canvas" ref="canvas3d" ></canvas>
</template>

<script>
import * as THREE from "three";
import * as ThreeScenes from "../three/scenes";
import Setting from '../Setting';
import { OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import { AnimationMixer } from 'three';
import * as MeshFunctions from '../util/MeshFunctions';
//import * as AsyncFunctions from '../util/AsyncFunctions';
const FixWebm = require('fix-webm-duration');//eslint-disable-line

//ここで定義しないとうまくいかない
let sotaScene;
let sotaCamera;
let animationMixer;

/*eslint-disable*/
const top_righthand ="top_righthand";
const top_lefthand ="top_lefthand";
const top_bothhand ="top_bothhand";
const front_bothhand ="front_bothhand";
const point_righthand ="point_righthand";
const imp_point_righthand ="imp_point_righthand";
const point_lefthand ="point_lefthand";
const imp_point_lefthand ="imp_point_lefthand";
const happy ="happy";
const veryhappy ="veryhappy";
const question ="question";
const think ="think";
const thank ="thank";
const nod ="nod";
const bye ="bye";
const sad ="sad";
const call ="call";
const support ="support";
const cough ="cough";
const mistake ="mistake";
const shy ="shy";
const fist_pump ="fist_pump";
const inspiration ="inspiration";
const shakehand ="shakehand";
const attract ="attract";
const right ="right";
const righthand ="righthand";
const left ="left";
const lefthand ="lefthand";
const head_view ="head_view";
const idle5 ="idle5";
const idle10 ="idle10";
const handclap ="handclap";
const step ="step";
const worry ="worry";
const point_camera ="point_camera";
const appeal ="appeal";
const deny ="deny";
const reject ="reject";
/*eslint-disable*/
export default {
  name: "ModelCanvas",
  data() {
    return{
      scene:null,
      render:null,
      loader:ThreeScenes.loader,
      nowAnimation:null,
      clock : new THREE.Clock(),
      recorder:null,
      tmpZ : null,
      videoStartTime:null,
      videoEndTime:null,
      loaded:false,
    };
  },
  props:{
    animation:{//Homeから渡されたプロパティの受け取り。受け取ったらthis.codeに渡す。
      type: String,
      default:'',
      required:false
    },
    talkText:{
      type:String,
      default:'',
      required:false
    },
    listenText:{
      type:String,
      default:'',
      required:false
    },
    listenWait:{
      type:Boolean,
      default:false
    },
    record:{
      type:Boolean,
      default:false,
      required:false
    }
  },
  mounted() {
    sotaScene = new THREE.Scene();
    sotaCamera = ThreeScenes.camera;
    sotaScene.add(ThreeScenes.light)

    this.render = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.$refs.canvas3d
    });
    this.render.setClearColor(Setting.ModelCanvas.backGround.color);

    //カメラぐりぐり出来るやつ
    this.control = new OrbitControls(sotaCamera,this.render.domElement);
    this.control.target = Setting.ModelCanvas.camera.lookAt;
    this.control.maxDistance = 1500;
    this.control.minDistance = 170;
    this.control.enablePan = false;
    this.control.enableDamping = true;
    this.control.dampingFactor = 0.2;
    this.control.minPolarAngle = 0;
		this.control.maxPolarAngle =  Math.PI * 0.5;

    //床代わりのグリッド線
    const plane = new THREE.GridHelper(10000, 100, 0x8FD3F5, 0x8FD3F5);
    //plane.position.y = -155;
    sotaScene.add(plane);

    ////Sotaの吹き出し
    const talkText = MeshFunctions.CreateTexture(this.talkText,true);
    const talksprite = MeshFunctions.createSprite( talkText, 'talkBox',-200, 380, 0);
    sotaScene.add(talksprite);
    ////
    //聞いた言葉の吹き出し
    const listenText = MeshFunctions.CreateTexture(this.listenText,false);
    const listenSprite = MeshFunctions.createSprite(listenText, 'listenBox',0, 60, 180);
    sotaScene.add(listenSprite);

    const roomGeo = new THREE.BoxGeometry(1000, 1000,1);
    const wallGeo = new THREE.BoxGeometry(500, 1000,1);
    const roomMesh = new THREE.MeshLambertMaterial({color:0xC6AC8F});
    const wallMesh = new THREE.MeshLambertMaterial({color:0xFFFFFF});
    const floor = new THREE.Mesh(roomGeo,roomMesh);
    floor.rotation.x = -0.5*Math.PI;
    const wall1 =new THREE.Mesh(wallGeo,wallMesh);
    wall1.position.set(500,245,0);
    wall1.rotation.y = -0.5*Math.PI;
    wall1.rotation.z = -0.5*Math.PI;
    const wall2 =new THREE.Mesh(wallGeo,wallMesh);
    wall2.position.set(0,245,-500);
    wall2.rotation.z = -0.5*Math.PI;
    sotaScene.add(floor,wall1,wall2);

    this.render.render(sotaScene, sotaCamera);
    
    this.loadModel();

    this.recorder = new MediaRecorder(this.$refs.canvas3d.captureStream(), {mimeType:'video/webm;codecs=vp9'});
    this.tick();
  },
  methods: {
    tick() {//ブラウザ準拠のレートで実行される関数
      //カメラコントロール
      this.control.update();
      //animation起動
      const delta = this.clock.getDelta();
      if(animationMixer){
        animationMixer.update(delta); 
      }


      //Sotaの吹き出しはSotaの近く、自分の吹き出しは自分の近くに配置されて、カメラの距離によってスケールを変更。
      //どこから見ても大体同じ大きさになるように。伴って、少しずつ場所を変える。
      //ここ、どうにかしたいけど……
      if(sotaScene !=null && sotaScene.getObjectByName('talkBox') !=null && sotaScene.getObjectByName('listenBox')!=null){
        const distance = Math.sqrt(Math.pow(sotaCamera.position.x,2)+ Math.pow(sotaCamera.position.y-160,2) + Math.pow(sotaCamera.position.z,2));//焦点までの距離
        sotaScene.getObjectByName('talkBox').position.x = -distance/2;
        sotaScene.getObjectByName('talkBox').position.y = distance/3 +200;
        sotaScene.getObjectByName('listenBox').position.x = sotaCamera.position.x*0.99+distance/240;
        sotaScene.getObjectByName('listenBox').position.y = sotaCamera.position.y*0.99-distance/240;
        sotaScene.getObjectByName('listenBox').position.z = sotaCamera.position.z*0.99;

        sotaScene.getObjectByName('talkBox').scale.set(distance/120*80, distance/240*80,distance/120*80);
        sotaScene.getObjectByName('listenBox').scale.set(distance/120, distance/240,distance/120);
      }

      //リサイズ時の制御
      const resizeRnder =MeshFunctions.ResizeRendererToDisplaySize(this.render);
      if (resizeRnder.needResize && sotaCamera!=null) {
        const canvas = this.render.domElement;
        sotaCamera.aspect = canvas.clientWidth / canvas.clientHeight;
        sotaCamera.updateProjectionMatrix();
        this.render.setSize(resizeRnder.width, resizeRnder.height, false);
      }

      this.render.render(sotaScene, sotaCamera);
      requestAnimationFrame(this.tick);
    },
    setMotion(motionName){
      //長くて見づらいけど、argのmotionNameフックでSotaObjからモーション引っ張り出しreset()をかけてからactに格納してるだけ
      const act = animationMixer.clipAction(sotaScene.getObjectByName("Sota").animations.find((a)=>(a.name).replace('アーマチュア|','') === motionName)).reset();
      if( act ){
        animationMixer.stopAllAction();//これしないと再生おわったactionが次に再生するactionと融合して変な動きになる
        act.setLoop(THREE.LoopOnce);
        act.clampWhenFinished = true;//アニメーション完了後一時停止する
        act.enable = true;//アニメーションの有効化
        act.play();
      }
    },
    loadModel(){//モデルのロード
      var self = this;//eslint-disable-line
      //fbxLoader改造して常にLambertMaterialで取り込むようにしてしまった。
      //多分他にいい方法あると思う
      this.loader.load(
        Setting.ModelCanvas.model.url,
        function(fbx){
          animationMixer = new AnimationMixer(fbx);
          animationMixer.addEventListener('finished',function(e){
            self.$parent.isOneActionFinished = true;
          })
          fbx.name = "Sota";
          fbx.position.y = 160;
          sotaScene.add(fbx);
          const act = animationMixer.clipAction(sotaScene.getObjectByName("Sota").animations.find((a)=>(a.name).replace('アーマチュア|','') === 'default')).reset();
          act.play();
          self.loaded=true;
          self.setMotion("front_bothhand");
          console.log(
            ' #######         ##    ##            ####     ##\n'+
            ' #   ##          ##    ##              ##\n'+
            '    ##    ####  ##### #####   ####     ##     ###  #####  ##  ##\n'+
            '   ##    ##  ##  ##    ##        ##    ##      ##  ##  ##  ####\n'+
            '  ##     ######  ##    ##     #####    ##   #  ##  ##  ##   ## \n'+
            ' ##    # ##      ## ## ## ## ##  ##    ##  ##  ##  ##  ##  #### \n'+
            ' #######  #####   ###   ###   #####   ####### #### ##  ## ##  ## \n'+
            '                             -ゼッタリンクス- '
          )

        },
        function(xhr){
          console.log('Sota Model ' + Math.round( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
        function ( error ) {
          console.error(error);
          alert('モデルデータのロードに失敗しました。画面を更新してください')
        }
      )
    },
  },
  watch:{
    animation:function(){
      console.group('now <model-canvas> animation: ');
      console.log(String(this.animation));
      console.groupEnd();
      eval(String(this.animation));
    },
    talkText:function(){
      console.log("talkText:"+ this.talkText);
      sotaScene.traverse((obj)=>{
        if(obj.name=='talkBox'){
          obj.material.map = MeshFunctions.CreateTexture(String(this.talkText),true);
        }
      });
    },
    listenText:function(){
      console.log("listenText:"+ this.listenText);
      sotaScene.traverse((obj)=>{
        if(obj.name=='listenBox'){
          obj.material.map = MeshFunctions.CreateTexture(String(this.listenText),false);
        }
      });
    },
    listenWait:function(){
      const sota = sotaScene.getObjectByName('Sota')
      if(this.listenWait){
        sota.traverse((obj)=>{
          if(obj.name=='body'){
            obj.material.find((mat)=>mat.name=='clear_eye').color = Setting.ModelCanvas.eye.blue;
          }
        });
      }else{
        sota.traverse((obj)=>{
          if(obj.name=='body'){
            obj.material.find((mat)=>mat.name=='clear_eye').color = Setting.ModelCanvas.eye.orange;
          }
        });
      }
    },
    record:function(){
      const chunk = [];
      if(this.record){
        var self = this;//eslint-disable-line
        this.recorder.ondataavailable = (e) => {
          if(e.data && 0< e.data.size){
            chunk.push(e.data);
          }
        }
        this.recorder.onstart = () =>{
          this.videoStartTime = Date.now();
        }
        this.recorder.onstop = () =>{
          const webm = new Blob(chunk, {'type':'video/webm;codecs=vp9'});
          const duration = Date.now() - this.videoStartTime;
          FixWebm(webm, duration, function(blob){
            const blobUrl = window.URL.createObjectURL(blob);
            self.$parent.videoBlobURL = blobUrl;
          })
        }
        this.recorder.start();
      }
      else{
        this.recorder.stop();
      }
    }
  }
};
</script>

<style>
#canvas{
  position: relative;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>