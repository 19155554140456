import * as Blockly from 'blockly/core';

export function codeSotaStart(code, model){
    if(model){
        return "this.stopFlag = false;\n" + code + "\nthis.stopFlag = true;"
    }else{
        return "alert('未実装の機能です')";
    }
}

export function codeSotaMotion(motion, model){
    if(model){
        return  "if(!this.stopFlag){\nthis.execution = 'this.setMotion("+ motion+")';\n this.isOneActionFinished=false;\n"+
        "while(this.isOneActionFinished==false){\n"+
        "await af.WaitForSec(50);"+"this.execution = '';\n"+"console.log(this.isOneActionFinished)}\n console.log('action finished')\n}";
    }else{
        //PWA時、model:falseで実際のSotaにRequestを送る
        //できるか分からない
        return "alert('未実装の機能です')";
    }
}
export function codeSotaWait(time, model){
    if(model){
        return "if(!this.stopFlag){\nawait af.WaitForSec("+time*1000+");\n}";
    }else{
        return ;
    }
}
export function codeSotaTalk(talk, model){
    if(model){
        return "if(!this.stopFlag){\nthis.talkText="+ talk + ";\nthis.isOneActionFinished=false;\n"+
        "await af.WaitForSec(1500);"+"this.execution = '';\nthis.isOneActionFinished = true;\n}";
        //+"await af.WaitForSec(1000);\n"
    }else{
        return ;
    }
}
export function codeSotaListen(model){
    if(model){
        return "if(!this.stopFlag){\nthis.listenWait = true;\ndocument.getElementById('listenWord').focus();\n"+
            "while(this.listenWait){"+
            "await af.WaitForSec(50);}\n}"
    }else{
        return ;
    }
}
export function codeSotaListenWord(model){
    if(model){
        return ['this.listenWord', Blockly.JavaScript.ORDER_ATOMIC];
    }else{
        "self.talkWord=\""+this.listenWord+"\";"
        return;
    }
}