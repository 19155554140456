import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
//import firebase from 'firebase'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import router from './router'
import store from './store'

const config = {
    apiKey: "AIzaSyCRzT6M9MdB-ZyI6AtFuy72iYinMn4eZUU",
    authDomain: "sota-simulation.firebaseapp.com",
    projectId: "sota-simulation",
    storageBucket: "sota-simulation.appspot.com",
    messagingSenderId: "346671500791",
    appId: "1:346671500791:web:c82b005383945e2984b2f2",
    measurementId: "G-FL3RR7QKKS"
};
firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
createApp(App).use(router).use(store).mount('#app')