import { TEXT_GET_SUBSTRING_INPUT_IN_TEXT } from "blockly/msg/en";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import home from "../pages/Home.vue";
import signIn from "../pages/SignIn.vue"
import store from '../store'

const routes:Array<any> =[
    {
        path:'/',
        name:'home',
        component:home,
        meta: { requiresAuth: true }
    },
    {
        path:'/signIn',
        name:'signIn',
        component:signIn,
    },
];

const router = createRouter({
    history:createWebHistory(process.env.BASE_URL),
    routes
});
//ナビゲーションガード

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Object.keys(store.state.userInfo).length) {
            next()
        }else {
            next({ path: '/SignIn', query: { redirect: to.fullPath }})
        }
    }else{
        next();
    }
})
export default router;