<template>
    <div class="userInfo">
        <img class="userImg" ref="userImg" />
        <div class="userText">
            <p ref="userName" class="userName"/>
            <p ref="userEMail" class="userEmail"/>
        </div>
    </div>
</template>

<script>
import store from '../store'
export default{
    name:'UserInfo',
    data(){
        return{
            userImg:null,
            userEMail:null,
            userName:null,
        }
    },
    mounted(){
        if(store.state){
            this.$refs.userImg.src = store.state.userInfo.photoURL;
            this.$refs.userName.innerHTML = store.state.userInfo.displayName;
            this.$refs.userEMail.innerHTML = store.state.userInfo.email;
        }
    }
}
</script>
<style scoped>
.userInfo{
    display: flex;
    align-items: center;
    justify-content: start; 
    width: 100%;
    height: 100%;
}
.userImg{
    border-radius:50%;
    height:80%;
    top:10%;
    margin: 0 1%;
}
.userText{
    display: block;
    left:10%;
}
.userName{
    font-size:1rem;
    font-weight: bold;
    margin:5% 0% 0% 0%;
    text-align: left;
}
.userEmail{
    margin:0;
    bottom: 0;
    font-size: 0.5rem;
}
</style>