<template>
    <div class="modelListen">
        <textarea class="word" id="listenWord" ref="listenWord" maxlength="40" @keydown.enter="enterDown" v-model="listenWord"  v-on:keydown.enter.exact.prevent placeholder="Sotaに話しかける言葉を入れてね"/>
        <button ref='btn' style="background:#DDD;" class="send" @click="send"><span ref="icon" class="icon-Talk icon" style="color:#FFF;"></span></button>
    </div>
</template>

<script>
export default {
    name:"ModelListen",
    components:{
    },
    data(){
        return{
            listenWord:""
        }
    },
    methods:{
        send(){
            console.log(this.$refs.listenWord.value);
            this.$parent.listenWord = this.$refs.listenWord.value;
            this.$parent.listenWait = false;
            this.$parent.isOneActionFinished= true;
            this.$refs.listenWord.value ="";
            this.$refs.icon.style.color="#FFFFFF";
            this.$refs.btn.style.background = '#DDDDDD';
        },
        enterDown(){
            this.$refs.btn.click();
        }
    },
    watch:{
        listenWord:function(){
            if(this.listenWord.length>0){
                this.$refs.icon.style.color="#00AFEC";
                this.$refs.btn.style.background = '#D8ECF2';
            }
        }
    }
}
</script>

<style scoped>

.icon{
    font-size: 3vw;
    height: auto;
    width: 100%;
}
.word{
    background: #F5F5F5;
    border-color: #CCCCCC;
    border-radius: 30px;
    font-size: 1.5rem;
    margin: 0 1%;
    width: 80%;
    height: 80%;
    text-align: center;
    resize: none;
    box-sizing: border-box;
}
.word:focus{
    outline: none;
    border-color: #00AFEC;
    box-shadow: 0 0 10px #00AFEC;
    background: #FFF;
    animation-name: flash;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
}
@keyframes flash {
    0%{
        box-shadow: none;
        transform: scale(1);
    }
    50% {
        box-shadow:0 0 20px #00AFEC;
        transform: scale(1.1);
    }
    100% {
        box-shadow: 0 0 10px #00AFEC;
        transform: scale(1);
    }
}
.word::placeholder{
    color: #ccc;
    font-size: 1.5vw;
}
.send{
    width: 80px;
    height: 80px;
    border:none;
    margin: 0 auto;
    border-radius: 50%;
}
.send:hover{
    box-shadow : 5px 5px 5px #DDD;
    transition: 0.2s;
}
button{
    width: 100%;
    height: auto;
}
.modelListen{
    margin: 0;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.modelListen:focus button{
    background: #D8ECF2;
}
.modelListen:focus button span{
    color: #00AFEC;
}
@font-face {
    font-family: 'blockIcon';
    src:  url('/fonts/icomoon.eot?25z2qg');
    src:  url('/fonts/icomoon.eot?25z2qg#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?25z2qg') format('truetype'),
        url('/fonts/icomoon.woff?25z2qg') format('woff'),
        url('/fonts/icomoon.svg?25z2qg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

    [class^="icon-"], [class*=" icon-"] {
    font-family:'blockIcon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

    .icon-Calc:before {
    content: "\e900";
    }
    .icon-Conditional:before {
    content: "\e901";
    }
    .icon-Repeat:before {
    content: "\e902";
    }
    .icon-Sota:before {
    content: "\e903";
    }
    .icon-Talk:before {
    content: "\e904";
    }
    .icon-Text:before {
    content: "\e905";
    }
    .icon-Variable:before {
    content: "\e906";
    }
</style>