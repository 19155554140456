/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define custom blocks.
 * @author abbychau@gmail.com (Abby Chau)
 */

// More on defining blocks:
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks


import * as Blockly from 'blockly/core';
import * as CodeGenerator from './CodeGenerator';
import Setting from "../Setting";

////////
//スタート
////////
Blockly.Blocks['sota_start'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(Blockly.Msg["sota_start"]);
    this.appendStatementInput("blocks")
        .setCheck(null);
    this.setColour("#00AFEC");
  this.setTooltip("このブロックに挟まれたブロックをSotaが実行します");
  this.setHelpUrl("");
  }
};
Blockly.JavaScript['sota_start'] = function(block) {
  const statements_sotablocks = Blockly.JavaScript.statementToCode(block, 'blocks');
  // TODO: Assemble JavaScript into code variable.
  const code = CodeGenerator.codeSotaStart(statements_sotablocks,true)
  return code;
};
////////
//右・左に向く
////////
Blockly.Blocks['sota_motion'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(Setting.ModelCanvas.motion), "motion")
        .appendField(Blockly.Msg["sota_motion"]);
    this.setColour("#00AFEC");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
  this.setTooltip("Sotaの指定した動きをします");
  this.setHelpUrl("");
  }
};
Blockly.JavaScript['sota_motion'] = function(block) {
  const motion = block.getFieldValue('motion');
  // TODO: Assemble JavaScript into code variable.
  const code = CodeGenerator.codeSotaMotion(String(motion),true);
  return code;
};

////////
//X秒間待つ
////////
Blockly.Blocks['sota_wait'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldTextInput("1"), "time")
        .appendField(Blockly.Msg["sota_wait"]);
    this.setColour("#00AFEC");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
  this.setTooltip("指定した秒数Sotaが待ちます");
  this.setHelpUrl("");
  }
};
Blockly.JavaScript['sota_wait'] = function(block) {
  let time = block.getFieldValue('time');
  let code;
  try{
    time = Number(time);
  }finally{
    code = CodeGenerator.codeSotaWait(time,true);
  }
  // TODO: Assemble JavaScript into code variable.
  return code;
};

////////
//話す
////////
Blockly.Blocks['sota_talk'] = {
  init: function() {
    this.appendDummyInput();
    this.appendValueInput("talk")
        .setCheck(null);
    this.appendDummyInput()
        .appendField(Blockly.Msg["sota_talk"])
    this.setColour("#00AFEC");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
  this.setTooltip("指定した言葉をSotaが話します");
  this.setHelpUrl("");
  }
};
Blockly.JavaScript['sota_talk'] = function(block) {
  const talk = Blockly.JavaScript.valueToCode(block, 'talk', Blockly.JavaScript.ORDER_NONE);
  const code = CodeGenerator.codeSotaTalk(String(talk),true);
  return code;
};

////////
//聞く
////////
Blockly.Blocks['sota_listen'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(Blockly.Msg["sota_listen"])
        .appendField(new Blockly.FieldImage('/img/Talk.svg',20,20,{alt:'*',flipRtl:'FALSE'}));
    this.setColour("#00AFEC");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
  this.setTooltip("入力があるまで待ちます。入力された言葉は[聞こえた言葉]に格納されます");
  this.setHelpUrl("");
  }
};
Blockly.JavaScript['sota_listen'] = function() {
  const code = CodeGenerator.codeSotaListen(true);
  return code;
};

////////
//聞こえた言葉
////////
Blockly.Blocks['sota_listenword'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(Blockly.Msg["sota_listenword"]);
    this.setOutput(true, null);
    this.setColour("#00AFEC");
  this.setTooltip("[なにか聞こえるまで待つ]で聞いた言葉です");
  this.setHelpUrl("");
  }
};
Blockly.JavaScript['sota_listenword'] = function() {
  const code = CodeGenerator.codeSotaListenWord(true);
  return code;
};