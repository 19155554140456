import * as THREE from "three";
export default{
    ModelCanvas:{
        motion:[
            ["右手を上げる","top_righthand"],
            ["左手を上げる","top_lefthand"],
            ["両手を上げる","top_bothhand"],
            ["右側を指す","point_righthand"],
            ["左側を指す","point_lefthand"],
            ["右側を指す（重要）","imp_point_righthand"],
            ["左側を指す（重要）","imp_point_lefthand"],
            ["ようこそ","front_bothhand"],
            ["うれしい","happy"],
            ["とてもうれしい","veryhappy"],
            ["忘れる/疑問","question"],
            ["考える","think"],
            ["お辞儀","thank"],
            ["うなずく","nod"],
            ["手を振る（バイバイ）","bye"],
            ["泣く","sad"],
            ["歓声に応える","call"],
            ["応援する","support"],
            ["咳をする","cough"],
            ["間違いに気づく","mistake"],
            ["照れる","shy"],
            ["ガッツポーズ","fist_pump"],
            ["ひらめく","inspiration"],
            ["握手","shakehand"],
            ["観衆にアピールする","attract"],
            ["胴体を右に振る","right"],
            ["右に手を出す","righthand"],
            ["胴体を左に振る","left"],
            ["左に手を出す","lefthand"],
            ["見渡す","head_view"],
            ["拍手","handclap"],
            ["歩く","step"],
            ["カメラを見せる","point_camera"],
            ["嫌がる","deny"],
            ["拒否する","reject"]
        ],
        model:{
            url:"model/Sota.fbx",
        },
        camera:{
            PerspectiveCamera:{
                fov:90,
                aspectX:600,
                aspectY:800,
                near:1,
                far:5000
            },
            position:{
                x:-100,
                y:50,
                z:500
            },
            lookAt:new THREE.Vector3(0, 160, 0)
        },
        eye:{
            orange:{
                r: 0.8000000715255737,
                g: 0.3775167763233185,
                b: 0.040493667125701904
            },
            blue:{
                r:0,
                g:1,
                b:1,
            }
        },
        light:{
            DirectionLight:{
                color:"0xFFFFFF",
                intensity:1
            },
            position:{
                x:0,
                y:300,
                z:200
            },
            HemisphereLight:{
                skyColor:0xFFFFFF,
                groundColor:0xBBBBEE,
                intensity:1
            }
        },
        sotaTalk:{
            background:{
                colorSota:'rgba(255, 255, 255, 1)',
                colorMe:'rgba(0, 175, 236, 1)',
                canvasWidth:1800,
                canvasHeight:1000
            },
            font:{
                colorSota:'black',//'rgba(0, 122, 255, 1)',
                colorMe:'white',
                style:"120px Meiryo bold"
            }
        },
        backGround:{
            color:0xD8ECF2
        }
    },
    Block:{
        //<block type="sota_start"></block>
        initial:'<xml xmlns="https://developers.google.com/blockly/xml"><block type="sota_start" id="Zp-T`Fi_fNYh,$XN?Q-G" deletable="false" x="0" y="0"></block></xml>',
        SetBlock:
        `<xml>
        <category name="じょうけん" colour="#F3C644">
            <block type="controls_if" color="#F3C644"></block>
            <block type="logic_compare"></block>
            <block type="logic_operation"></block>
            <block type="logic_negate"></block>
            <block type="logic_boolean"></block>
        </category>
        <category name="くり返し" colour="#A7ACF0">
            <block type="controls_repeat_ext">
                <value name="TIMES">
                    <block type="math_number">
                        <field name="NUM">10</field>
                    </block>
                </value>
            </block>
            <block type="controls_whileUntil"></block>
        </category>
        <category name="計算" colour="#F3A9C4">
            <block type="math_number">
                <field name="NUM">123</field>
            </block>
            <block type="math_arithmetic"></block>
            <block type="math_single"></block>
            <block type="math_round"></block>
            <block type="math_trig"></block>
            <block type="math_modulo"></block>
            <!--<block type="math_on_list"></block>-->
            <block type="math_random_int"></block>
        </category>
        <category name="文章" colour="#43D5CD">
            <block type="text"></block>
            <block type="text_join"></block>
            <block type="text_length"></block>
            <block type="text_print"></block>
            <block type="text_replace"></block>
        </category>
        <category name="へん数" colour="#8FD3F5" custom="VARIABLE">
        </category>
        <category name="Sota" colour="#00AFEC">
            <block type="sota_motion"></block>
            <block type="sota_wait"></block>
            <block type="sota_talk">
                <value name="talk">
                    <block type="text"></block>
                </value>
            </block>
            <block type="sota_listen"></block>
            <block type="sota_listenword"></block>
        </category>
    </xml>`
    }
}