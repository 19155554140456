<template>
    <div id="home">
        <modal-setting class="settingButton"/>
        <img class="homelogo" ref="homelogo" @click="logoClick" src="/img/SotaRoom_Logo.png"/>
        <div id= "left">
            <div class="head1">
                <svg-button svgImg="/img/download.svg" @click="blockSave">ほぞん</svg-button>
                <svg-button svgImg="/img/upload.svg" @click="loadClk">読みこみ</svg-button>
                <input type="file" ref="loadFile" id="loadFile" @change="blockLoad" accept=".stbcw"/>
            </div>
            <blockly-component id="blockly" :options="options" ref="foo"></blockly-component>
        <div class="controlls">
            <button class="execAndStop" v-if="stopFlag==true" @click="execute" style="background:#15CA90">
                <table>
                    <tr>
                        <td><img src="/img/execution.svg"/></td>
                        <td><div class="butonDetail" style="color:#FFF; font-size:1.5vw;">スタート</div></td>
                    </tr>
                </table>
            </button>
            <button class="execAndStop" v-else @click="stopFlag=true" style="background:#FC3333">
                <table>
                    <tr>
                        <td><img src="/img/stop.svg"/></td>
                        <td><div class="butonDetail"  style="color:#FFF; font-size:1.5vw;">ストップ</div></td>
                    </tr>
                </table>
            </button>
            <reverse-when-focus class="RECBtnStyle" v-if="record==false" svgimage="./img/record.svg" themaColor="#00AFEC" @click="record=true">録画</reverse-when-focus>
            <reverse-when-focus class="RECBtnStyle" v-else svgimage="./img/recordend.svg" themaColor="#ff2825" @click="record=false">停止</reverse-when-focus>
        </div>
        </div>
        <div id="right">
            <div class="head2">
                <user-info/>
            </div>
            <div ref="simulation" class="simulation" id="simulation">
                <div class="modelView">
                    <model-canvas :animation="execution" :talkText="talkText" :listenText="listenWord" :record="record" :pause="pause" :listenWait="listenWait"></model-canvas>
                </div>
            </div>
            <div style="height:12%">
                <model-listen refs="listen"></model-listen>
            </div>
        </div>
    </div>
</template>

<script>
import ModelCanvas from '../components/ModelCanvas.vue';
import BlocklyComponent from '../components/BlocklyComponent.vue';
import ModelListen from '../components/ModelListen.vue';
import ReverseWhenFocus from '../components/parts/ReverseWhenFocusButton';
import ModalSetting from '../components/ModalSetting.vue'
import '../blocks/SotaMotion.js';
import BlocklyJS from 'blockly/javascript';
import Blockly, { mainWorkspace } from 'blockly/core';
import 'blockly/blocks';
import locale from 'blockly/msg/ja';
import * as AsyncFunctions from '../util/AsyncFunctions';
import Setting from '../Setting';
import UserInfo from '../components/UserInfo.vue';
import SvgButton from '../components/parts/SvgButton.vue';

Blockly.setLocale(locale);//ブロックの言語設定

export default{
    name:"Home",
    components: {
        ModelCanvas,
        BlocklyComponent,
        ReverseWhenFocus,
        ModelListen,
        UserInfo,
        ModalSetting,
        SvgButton,
    },
    data(){
        return{
        execution:'',
        talkText:'',
        listenWord:'',
        listenWait:false,
        isOneActionFinished:false,
        videoBlobURL:null,
        stopFlag:true,
        record:false,
        pause:false,
        options: {
            media: 'https://blockly-demo.appspot.com/static/media/',
            grid:
            {
            spacing: 25,
            length: 3,
            colour: '#ccc',
            snap: true
            },
            toolbox:Setting.Block.SetBlock
        }
        }
    },
    methods:{
        execute(){
            this.isOneAnimationFinished = false;
            const code = BlocklyJS.workspaceToCode(this.$refs["foo"].workspace);
            const asFunc = AsyncFunctions;//eslint-disable-line
            this.talkText = "";
            this.listenWord = "";
            try{
                console.log("(async(af)=>{" + code + "})(asFunc)");
                eval("(async(af)=>{\n" + code + "\n})(asFunc)");
            }catch(e){
                console.error(e);
                this.stopFlag = true;
            }
        },
        blockSave(){
            const xmlDom = Blockly.Xml.workspaceToDom(this.$refs["foo"].workspace);
            const xmlText = Blockly.Xml.domToPrettyText(xmlDom);
            const blob = new Blob([ xmlText ], { "type" : "text/plain" });
            if (window.navigator.msSaveBlob) { 
                window.navigator.msSaveBlob(blob, "ソータブロック.stbcw"); 
                console.log("ms")
            } else {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.target = '_blank';
                a.download = 'ソータブロック.stbcw';
                a.click();
            }
        },
        loadClk(){
            this.$refs.loadFile.click();
        },
        blockLoad(e){
            //↓コメントアウトはworkspace自体じゃなくて、workspaceのproxyObjectが渡されるらしい。workspace挿入後の編集ができなくなる。注意。
            const workspace = Blockly.mainWorkspace;//this.$refs["foo"].workspace;
            const fileList =  e.target.files;
                //読み込み
            if(fileList[0]==null){
                return;
            }
            const reader = new FileReader();
            reader.readAsText(fileList[0]);
            //読み込み後
            reader.onload = function  () {
                const xmlText = reader.result;
                try {
                    const xmlDom = Blockly.Xml.textToDom(xmlText);
                    workspace.clear();
                    Blockly.Xml.domToWorkspace(xmlDom, workspace);
                } catch (e) {
                    window.alert("読込に失敗しました")
                    console.log(e);
                }
            };
        },
        logoClick(){
            //遊び。必要ないけど、ロゴで少し遊べるように。
            this.$refs.homelogo.classList.remove('logoRotate');
            this.$refs.homelogo.classList.remove('logoFall');
            this.$refs.homelogo.classList.remove('logoHurueru');
            const probability = Math.random();
            if(probability > 0.99){
                this.$refs.homelogo.classList.add('logoFall');
            }else if(probability>0.95){
                this.$refs.homelogo.classList.add('logoRotate');
            }else if(probability>0.8){
                this.$refs.homelogo.classList.add('logoHurueru');
            }
        }
    },
    mounted(){
        //アイコン埋め込み。多分もっといい方法ある。思いつかなかった。頼みます。
        const btl = document.getElementsByClassName('blocklyTreeLabel');
        btl[0].innerHTML = '<span class="icon-Conditional icon"></span>'+btl[0].innerHTML;
        btl[1].innerHTML = '<span class="icon-Repeat icon"></span>'+btl[1].innerHTML;
        btl[2].innerHTML = '<span class="icon-Calc icon"></span>'+btl[2].innerHTML;
        btl[3].innerHTML = '<span class="icon-Text icon"></span>'+btl[3].innerHTML;
        btl[4].innerHTML = '<span class="icon-Variable icon"></span>'+btl[4].innerHTML;
        btl[5].innerHTML = '<span class="icon-Sota icon"></span>'+btl[5].innerHTML;

        const workspace = Blockly.mainWorkspace;
        const xmlDom = Blockly.Xml.textToDom(Setting.Block.initial);
        workspace.clear();
        Blockly.Xml.domToWorkspace(xmlDom, workspace);
        
    },
    watch:{
        videoBlobURL:function() {
            const anchor = document.createElement("a");
            anchor.download = 'SotaMovie.webm';
            anchor.href = this.videoBlobURL;
            anchor.target = "_blank"
            anchor.style.display = 'block';
            anchor.click();
        },
        isOneActionFinished:function(){
        if(this.isOneActionFinished==true){
            setTimeout(()=>{
                this.isOneActionFinished = false;
            },100)
        }
        }
    }
}
</script>

<style>
    body{
        margin: 0;
        padding: 0;
        background:#F9F9F9;
    }
    input{
        display: none;
    }
    label{
        height: 100%;
        width: 100%;
        font-size: 12px;
    }
    #home {
        margin: 0;
        width: auto;
        height: 100vh;
        display: flex;
        position: relative;
        min-height: 576px;
        min-width: 1024px;
    }
    #left{
        height: 100%;
        width: 60%;
    }
    #right{
        height: 100%;
        width: 40%;
    }
    .homelogo{
        position: absolute;
        height: 5%;
        margin: 0.5%;
    }
    .head1{
        height: 8%;
        background:#F9F9F9;
        display: flex;
        justify-content: flex-end;
        margin: 0 auto;
        align-items: center;
    }
    .head2{
        height: 8%;
        background: #FFF;
    }
    .controlls{
        display: flex;
        background: #F9F9F9;
        align-items: center;
        justify-content: space-around;
        border:1px solid #E6E6E6;
        box-sizing: border-box;
        width: 100%;
        height: 12%;
    }
    #blockly {
        width: 100%;
        height: 80%;
        z-index: 1;
    }
    .simulation{
        width: 100%;
        height: 80%;
    }
    .modelView{
        height: 100%;
        width: 100%;
    }
    .butonDetail{
        font-weight: bold;
        display: block;
        margin: 0 auto;
    }
    .execAndStop{
        width: 40%;
        height: 50%;
        min-height: 50px;
        border-radius: 30px;
        border: 0;
        margin: 0 20%;
        opacity:0.8;
    }
    .execAndStop:hover{
        transition: 0.3s;
        opacity: 1;
    }
    .execAndStop:active{
        opacity: 1;
    }
    .RECBtnStyle{
        width: 10%;
        min-width: 100px;
        min-height: 50px;
        height: 50%;
        transition: 0.5s;
        white-space: pre;
        font-size :1rem;
    }
    table{
        margin:0 auto;
    }
    .settingButton{
        z-index: 2;
        position: absolute;
        right: 0;
        top: 0;
        height: 5%;
        margin :10px;
    }
    .icon{
        margin:3px;
        font-size: 30px;
    }
    .logoFall{
        z-index: 100;
        transition:all 1s ease-in-out;
        transform: translate(0, 1450%) rotate(250deg);
    }
    .logoRotate{
        z-index: 100;
        transition:all .5s;
        transform: rotate(360deg);
    }
    .logoHurueru{
        animation-name: hurueru ;
        animation-duration:.05s ;
        animation-iteration-count: 5;
    }
    @keyframes hurueru {
    0% {transform: translate(0px, 0px) rotateZ(0deg)}
    25% {transform: translate(3px, 3px) rotateZ(3deg)}
    50% {transform: translate(0px, 3px) rotateZ(0deg)}
    75% {transform: translate(3px, 0px) rotateZ(-3deg)}
    100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
/**ブロックツールバー */
    .blocklyTreeRow{
        height: 10%;
        margin-bottom: 10%;
    }
    .blocklyTreeLabel{
        font-size: 1.2rem;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
    .blocklyToolboxDiv{
        width:150px;
    }
    .blocklyToolboxDiv::-webkit-scrollbar {
        display: none;
    }


    @font-face {
    font-family: 'blockIcon';
    src:  url('/fonts/icomoon.eot?25z2qg');
    src:  url('/fonts/icomoon.eot?25z2qg#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?25z2qg') format('truetype'),
        url('/fonts/icomoon.woff?25z2qg') format('woff'),
        url('/fonts/icomoon.svg?25z2qg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
    }

    [class^="icon-"], [class*=" icon-"] {
    font-family:'blockIcon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

    .icon-Calc:before {
    content: "\e900";
    }
    .icon-Conditional:before {
    content: "\e901";
    }
    .icon-Repeat:before {
    content: "\e902";
    }
    .icon-Sota:before {
    content: "\e903";
    }
    .icon-Talk:before {
    content: "\e904";
    }
    .icon-Text:before {
    content: "\e905";
    }
    .icon-Variable:before {
    content: "\e906";
    }
</style>